<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentType"
                    label="付款方式"
                >
                    <RemoteSelect
                        clearable
                        remote="/sp/shop/getShopPrepaidPaymentTypeList"
                        placeholder="请输入"
                        :props="{id:'id',name:'name'}"
                        v-model="queryFormModel.paymentType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <el-row
                class="second-header bc-t npa-a"
                :gutter="12"
            >
                <el-col :span="24">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                        >
                            <div class="icon bc-main tc-w">
                                <div class="f-icon">
                                    &#xf02c;
                                </div>
                                <div class="label">
                                    合计
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    订单数量
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ buyerOpenStoreOrderStat.orderNum || 0 }}
                                </div>
                                <div>(订单金额 {{ buyerOpenStoreOrderStat.payableAmount || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="paymentSn"
                    label="付款单号"
                    min-width="100"
                />
                <el-table-column
                    label="付款时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="paymentAmount"
                    label="订单金额"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款方式"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTypeName }}<span v-if="scope.row.paymentComments">/{{ scope.row.paymentComments }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'BuyerOpenStoreOrderStatList',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
                paymentType: '',
            },
            // 表格数据
            tableData: [],
            buyerOpenStoreOrderStat: {},
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.OpenStoreOrderStat.openStoreOrderStatList(queryData).then(json => {
                const res = json.data.data.orderList;
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.buyerOpenStoreOrderStat = json.data.data.buyerOpenStoreOrderStat;
                return Promise.resolve({ data: res });
            });
        },
        /* -- 事件 --*/
    },
    created() {
        this.queryFormModel.statDateDay = [this.$utils.Common.getSysDayDate(), this.$utils.Common.getSysDayDate()];
    },
};
</script>

<style lang="scss" scoped>
</style>
